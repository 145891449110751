var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-input-wrapper flex" }, [
    _vm.showFormat
      ? _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.isDateInFuture
                  ? "Future dates not available"
                  : _vm.qWarning
                  ? _vm.QUARTER_WARNING_TEXT
                  : "",
                expression:
                  "isDateInFuture ? 'Future dates not available' : qWarning ? QUARTER_WARNING_TEXT : ''",
              },
            ],
            staticClass: "formattedValue",
            class: [
              _vm.statusClass,
              _vm.isDateInFuture ? "disable-future" : "",
            ],
            on: {
              click: function ($event) {
                !_vm.isDateInFuture ? _vm.toggleFormat(false) : ""
              },
            },
          },
          [
            _vm.formatType === "performance"
              ? [
                  _vm.value !== ""
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.formatNumber(_vm.value, 1, ".", ",", "")) +
                            "%"
                        ),
                      ])
                    : _vm._e(),
                ]
              : _vm.formatType === "premium"
              ? [
                  _vm.value !== ""
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatNumber(_vm.value, 2, ".", ",", "")
                            ) +
                            "%"
                        ),
                      ])
                    : _vm._e(),
                ]
              : _vm.formatType === "multiple"
              ? [
                  _vm.value !== ""
                    ? _c("span", [_vm._v(_vm._s(_vm.value) + "x")])
                    : _vm._e(),
                ]
              : _vm.formatType === "text"
              ? [
                  _vm.value !== ""
                    ? _c("span", [_vm._v(_vm._s(_vm.value))])
                    : _vm._e(),
                ]
              : [
                  _vm.value !== ""
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.formatNumber(_vm.value, 2, ".", ",", ""))
                        ),
                      ])
                    : _vm._e(),
                ],
          ],
          2
        )
      : _vm._e(),
    _c(
      "input",
      _vm._g(
        _vm._b(
          {
            ref: "textInput",
            attrs: { type: "text" },
            domProps: { value: _vm.formattedValue },
            on: {
              blur: function ($event) {
                return _vm.toggleFormat(true)
              },
              focus: function ($event) {
                return _vm.focusInput($event.target)
              },
            },
          },
          "input",
          _vm.$attrs,
          false
        ),
        _vm.listeners
      )
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }