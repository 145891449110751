






































import { Component, Vue, Prop } from 'vue-property-decorator';
import { formatNumber } from '@/utils';
import {TInputStatus} from '@/types/TInputStatus';

@Component({ inheritAttrs: false })
export default class TextInputWithFormattedOverlay extends Vue {
    @Prop({ type: [String, Number], required: true }) value!: number | string;

    @Prop({type: String, required: false, default: ''}) statusClass: TInputStatus;

    @Prop({type: String, required: false}) formatType: string;

    @Prop({type: Boolean, required: false}) isDateInFuture: boolean;

    @Prop({ type: Boolean, default: false }) qWarning: boolean;

    public QUARTER_WARNING_TEXT = 'Quarter total & monthly values outside expected range';

    public showFormat = true;

    public formatNumber = formatNumber;

    get formattedValue (): string {
        let result = '';
        switch (this.formatType) {
        case 'performance': {
            result = this.isDateInFuture
                ? ''
                : formatNumber(this.value, 1, '.', ',', '');
            break;
        }
        case 'premium': {
            result = formatNumber(this.value, 2, '.', ',', '');
            break;
        }
        case 'multiple':
        case 'text': {
            result = this.value.toString();
            break;
        }
        default: {
            result = formatNumber(this.value, 2, '.', ',', '');
            break;
        }
        }
        return result;
    }

    public get listeners (): unknown {
        return {
            ...this.$listeners,
        };
    }

    public toggleFormat (showFormat: boolean): void {
        this.showFormat = showFormat;
        if (showFormat === false) {
            this.focusInput();
        }
    }

    focusInput (target?: EventTarget): void {
        if (this.showFormat) {
            this.showFormat = false;
        }

        (this.$refs.textInput as HTMLInputElement).focus();
        target ? (target as HTMLInputElement).select() : '';
    }
}
